import "./reset.css"
import "./functional.css"
import "./icons.css"

import "./vars.css"
import "./typo.css"
import "./layout.css"
import "./admin.css"

import "./buttons.css"
import "./f3cc.css"
import "./details.css"
import "./embeds.css"
import "./faq.css"
import "./header.css"
import "./footer.css"
import "./forms.css"
import "./messages.css"
import "./card.css"
import "./downloads.css"
import "./gallery.css"

import { setDetailsHeights } from "./details.js"
import { onReady, qsa } from "./utils.js"

import { initForms } from "./forms.js"
onReady(initForms)

import "./textareas.css"
import { initTextareas } from "./textareas.js"
onReady(initTextareas)

onReady(() => {
  setDetailsHeights("details")
})

onReady(async () => {
  const galleries = qsa(".swiper")
  if (galleries.length) {
    const { renderGallery } = await import(
      /* webpackPrefetch: true */ "./gallery.js"
    )
    galleries.forEach(renderGallery)
  }
})
