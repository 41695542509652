export function initTextareas() {
  const textareas = document.querySelectorAll(".widget--textarea textarea")

  textareas.forEach((textarea) => {
    const wrap = document.createElement("div")
    wrap.className = "grow-wrap"
    textarea.before(wrap)
    wrap.append(textarea)
    const replicate = () => {
      wrap.dataset.replicatedValue = textarea.value
    }
    textarea.addEventListener("input", replicate)
    replicate()
  })
}
